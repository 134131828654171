import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Heading,
  Hr,
  MarketingIcon,
  Modal,
  Span,
} from "@icg360/design-system";

import { screenSizes } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  type ShingleResealContextState,
} from "pages/offer/shingle-reseal/shingle-reseal-flow";
import { trackEvent } from "utils";

import styles from "./shingle-reseal-landing.module.scss";

export const ShingleResealLanding = () => {
  const isMobile = useMediaQuery({ maxWidth: screenSizes.laptop });
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const {
    state: { from },
    formNavigate,
  } = useMultiStepFormState<ShingleResealContextState>(ShingleContext);

  const BackLink = () => (
    <Button
      appearance="tertiary"
      className={styles.backBtn}
      data-testid="shingle-pilot-offer-back-btn"
      onPress={() => navigate(from)}
      leadingIcon="Back"
    >
      Back
      {from === "/my/overview" && " to Overview"}
      {from === "/my/settings/home-protection" && " to Home Protection"}
    </Button>
  );

  const MainInfo = () => (
    <div>
      <Heading size="lg" className={styles.heading}>
        Strengthen your roof, one shingle at a time
      </Heading>
      <div className={styles.descriptionText}>
        <Span color="quiet">
          Research shows that roofs as new as 4 years old have an average of 19
          loose shingles¹, which can become lifted during high-wind events and
          lead to tens of thousands of dollars in damage.
        </Span>
        <Span color="quiet">
          SageSure partners with LiftLock to provide a shingle resealing
          service, which helps prevent lifted shingles and the damage they can
          cause. Based on storm conditions and availability, you may be selected
          to receive the service at no charge.
        </Span>
      </div>

      <div className={styles.stepList}>
        <Heading size="md" className={styles.listHeading}>
          How do I participate?
        </Heading>
        <ul className={styles.getStartedList}>
          <li className={styles.listItem}>
            <div className={styles.iconBackground}>
              <MarketingIcon size="sm" name="Computer" />
            </div>
            <span className={styles.number}>1</span>
            <div className={styles.itemText}>
              <Span bold color="default">
                Join the list
              </Span>
              <Span color="quiet">
                Sign up to join thousands of homeowners like you who value a
                resilient home.
              </Span>
            </div>
            <div className={styles.dashedLine} />
          </li>
          <li className={styles.listItem}>
            <div className={styles.iconBackground}>
              <MarketingIcon size="sm" name="Talk" />
            </div>
            <span className={styles.number}>2</span>
            <div className={styles.itemText}>
              <Span bold color="default">
                Get notified if selected
              </Span>
              <Span color="quiet">
                LiftLock will monitor the weather in your area and get in touch
                with you to schedule a visit if selected.
              </Span>
            </div>
            <div className={styles.dashedLine} />
          </li>
          <li className={styles.listItem}>
            <div className={styles.iconBackground}>
              <MarketingIcon size="sm" name="Tools" />
            </div>
            <span className={styles.number}>3</span>
            <div className={styles.itemText}>
              <Span bold color="default">
                Get loose shingles resealed
              </Span>
              <Span color="quiet">
                If selected, a trained professional will arrive before a
                hurricane makes landfall to reseal any loose shingles on your
                home.
              </Span>
            </div>
          </li>
        </ul>
      </div>
      <div className={styles.mainInfoFooter}>
        <Hr spacing="sm" />
        <Span size="sm" color="quiet">
          1: According to 2023 SageSure proprietary research on shingle
          resealing.
        </Span>
      </div>
    </div>
  );

  const LearnMoreModal = () => (
    <Modal
      open={showModal}
      heading="How does it work?"
      onClose={() => setShowModal(false)}
      size="lg"
      noFooter
    >
      <ul className={styles.modalSteps}>
        <li className={styles.modalStep}>
          <img
            height="126px"
            width="126px"
            src="/assets/images/shingle-leafblower-circle.jpg"
            alt="Person using a leafblower on a shingled roof"
          />
          <span className={styles.number}>1</span>
          <Span color="quiet" className={styles.modalStepDetails}>
            A trained technician tests shingle adhesion with a leaf blower.
          </Span>
        </li>

        <li className={styles.modalStep}>
          <img
            height="126px"
            width="126px"
            src="/assets/images/shingle-reseal-circle.jpg"
            alt="Person using a leafblower on a shingled roof"
          />
          <span className={styles.number}>2</span>
          <Span color="quiet" className={styles.modalStepDetails}>
            Unsealed shingles are identified with a water-soluble chalk that
            washes away in the rain.
          </Span>
        </li>

        <li className={styles.modalStep}>
          <img
            height="126px"
            width="126px"
            src="/assets/images/shingle-detail-circle.jpg"
            alt="Person using a leafblower on a shingled roof"
          />
          <span className={styles.number}>3</span>
          <Span color="quiet" className={styles.modalStepDetails}>
            Shingles are resealed with an approved industrial-grade adhesive to
            ensure a lasting bond.
          </Span>
        </li>
      </ul>
    </Modal>
  );

  const SupplementalInfo = () => (
    <>
      <div>
        <video
          autoPlay
          loop
          muted
          playsInline
          className={styles.video}
          poster="/assets/images/shingle-video-poster.jpg"
          src="/assets/videos/RoofSealing-1.mp4"
        />
        <div className={styles.overlay} />
      </div>
      <div className={styles.quoteBox}>
        <Span>
          <Span italic className={styles.quote}>
            &ldquo;
          </Span>
          My family with a roof over our heads is very important to me and
          SageSure recognizes this and took steps to ensure it continues.”
        </Span>
        <Span className={styles.quotee}>
          - Clayton J, Panama City Beach, FL
        </Span>
      </div>
    </>
  );

  const MobileLayout = () => (
    <>
      <div className={styles.mobileContainer}>
        <BackLink />
        <SupplementalInfo />
        <MainInfo />
      </div>
      {!showModal ? (
        <div className={styles.stickyFooter}>
          <div className={styles.buttons}>
            <Button
              appearance="secondary"
              size="sm"
              className={styles.mobileButton}
              onPress={() => setShowModal(true)}
              data-testid="info-modal-btn"
            >
              How it works
            </Button>
            <Button
              size="sm"
              className={styles.mobileButton}
              onPress={() => {
                trackEvent("shinglesForm_landing_JoinClicked");
                formNavigate("policy-confirmation");
              }}
              data-testid="get-started-btn"
            >
              Get started
            </Button>
          </div>
        </div>
      ) : null}
      <LearnMoreModal />
    </>
  );

  const DesktopLayout = () => (
    <>
      <div className={styles.header}>
        <BackLink />
      </div>
      <div className={styles.columnContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.leftContent}>
            <MainInfo />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.rightContent}>
            <SupplementalInfo />
            <div className={styles.buttons}>
              <Button appearance="secondary" onPress={() => setShowModal(true)}>
                How it works
              </Button>
              <Button
                onPress={() => {
                  trackEvent("shinglesForm_landing_JoinClicked");
                  formNavigate("policy-confirmation");
                }}
                data-testid="landing-get-started-btn"
              >
                Get started
              </Button>
            </div>
          </div>
        </div>
      </div>
      <LearnMoreModal />
    </>
  );

  return isMobile ? <MobileLayout /> : <DesktopLayout />;
};
