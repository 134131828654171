import { Container } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Link as DSLink,
  Heading,
  Hr,
  MarketingIcon,
  Span,
} from "@icg360/design-system";

import { screenSizes } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { TingContext, type TingContextState } from "./ting-flow";
import styles from "./ting-offer.module.scss";

export const TingLanding = () => {
  const isMobile = useMediaQuery({ maxWidth: screenSizes.laptop });
  const navigate = useNavigate();
  const {
    state: { from },
    formNavigate,
  } = useMultiStepFormState<TingContextState>(TingContext);

  const BackLink = () => (
    <Button
      appearance="tertiary"
      className={styles.backBtn}
      data-testid="ting-offer-back-btn"
      onPress={() => navigate(from)}
      leadingIcon="Back"
    >
      Back
      {from === "/my/overview" && " to Overview"}
      {from === "/my/settings/home-protection" && " to Home Protection"}
    </Button>
  );

  const GetStartedList = () => (
    <>
      <div>
        <Heading size="md" className={styles.listHeading}>
          How do I get started?
        </Heading>
        <div className={styles.dottedLine} />
        <ul className={styles.getStartedList}>
          <li className={styles.listItem}>
            <div className={styles.iconBackground}>
              <MarketingIcon size="sm" name="Computer" />
            </div>
            <span className={styles.number}>1</span>
            <Span color="default">Enroll right here</Span>
          </li>
          <li className={styles.listItem}>
            <div className={styles.iconBackground}>
              <MarketingIcon size="sm" name="Present" />
            </div>
            <span className={styles.number}>2</span>
            <Span color="default">Get your welcome package from Ting</Span>
          </li>
          <li className={styles.listItem}>
            <div className={styles.iconBackground}>
              <MarketingIcon size="sm" name="Tools" />
            </div>
            <span className={styles.number}>3</span>
            <Span color="default">Set Ting up in just two minutes</Span>
          </li>
          <li className={styles.listItem}>
            <div className={styles.iconBackground}>
              <MarketingIcon size="sm" name="ShieldHouse" />
            </div>
            <span className={styles.number}>4</span>
            <Span color="default">Enjoy peace of mind</Span>
          </li>
        </ul>
      </div>
      <div className={styles.leftContentFooter}>
        <Hr spacing="sm" />
        <Span size="sm" color="quiet">
          Ting is not an emergency notification system or any fire or smoke
          detector.
        </Span>
      </div>
    </>
  );

  const MainContent = () => (
    <>
      <div>
        <Heading size="lg" className={styles.heading}>
          Ting can help protect your home from devastating electrical fires
        </Heading>
        <Span>
          We&apos;re offering Ting&apos;s fire safety service to our customers
          absolutely free. Watch the video to see how it works.
        </Span>
      </div>
      <iframe
        src="https://player.vimeo.com/video/354300475?h=0808bd9bf2&color=f0c319&title=0&byline=0&portrait=0"
        allow="fullscreen; picture-in-picture"
        title="vimeo video player"
        className={styles.video}
      />
    </>
  );

  const GetTing = () => (
    <>
      <Button
        fluid
        className={styles.getTingButton}
        onPress={() => {
          formNavigate("policy-confirmation");
        }}
      >
        Get Ting for free
      </Button>
      <Span size="sm">
        <div className={styles.tingFaqs}>
          <div>Have more questions? Check out</div>
          {/* TODO: Confirm faq URL; see PHX-460 */}
          <DSLink href="https://www.tingfire.com/help/faq-2/" target="_blank">
            Ting&apos;s FAQs
          </DSLink>
        </div>
      </Span>
    </>
  );

  const MobileLayout = () => (
    <>
      <div className={styles.mobileContainer}>
        <BackLink />
        <div>
          <img
            className={styles.mobileImage}
            src="/assets/images/ting-promo.jpg"
            alt="a mobile phone using the ting app and a ting device"
          />
        </div>
        <MainContent />
        <GetStartedList />
      </div>
      <div className={styles.stickyFooter}>
        <GetTing />
      </div>
    </>
  );

  const DesktopLayout = () => (
    <Container>
      <BackLink />
      <div className={styles.columnContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.leftContent}>
            <MainContent />
            <GetStartedList />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.rightContent}>
            <img
              className={styles.tingImage}
              src="/assets/images/ting-promo.jpg"
              alt="a mobile phone using the ting app and a ting device"
            />
            <GetTing />
          </div>
        </div>
      </div>
    </Container>
  );

  return isMobile ? <MobileLayout /> : <DesktopLayout />;
};
