import { useNavigate } from "react-router-dom";

import { Button, type ButtonProps } from "@icg360/design-system";

import styles from "./multi-step-form-layout.module.scss";

interface ButtonBase {
  type?: ButtonProps["type"];
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  hide?: boolean;
}

interface ButtonWithOnPress extends ButtonBase {
  onPress?: ButtonProps["onPress"];
  path?: never;
}
interface ButtonWithPath extends ButtonBase {
  path?: string;
  onPress?: never;
}

type MultiStepFormButtonProps = ButtonWithOnPress | ButtonWithPath;

export type MultiStepFormButtonsProps = {
  primary: MultiStepFormButtonProps;
  back?: MultiStepFormButtonProps;
  cancel?: MultiStepFormButtonProps;
  formNavigate?: (path: string | number) => void;
};

// By default, the cancel button will navigate to "/" and the back button will do a browser back
// If you use `onPress` with both `back` and `primary` you don't need to provide `formNavigate`, otherwise, you do.
export const MultiStepFormButtons = ({
  primary,
  back,
  cancel,
  formNavigate,
}: MultiStepFormButtonsProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.buttonFooter}>
      <div className={styles.buttonWrapper}>
        {!cancel?.hide ? (
          <Button
            appearance="tertiary"
            type={cancel?.type}
            onPress={cancel?.onPress ?? (() => navigate(cancel?.path ?? "/"))}
            disabled={cancel?.disabled}
            loading={cancel?.loading}
            data-testid="multi-step-cancel"
          >
            {cancel?.text ?? "Cancel"}
          </Button>
        ) : null}
        <div className={styles.navButtonWrapper}>
          {!back?.hide ? (
            <Button
              appearance="secondary"
              type={back?.type}
              onPress={
                back?.onPress ??
                (formNavigate
                  ? () => formNavigate(back?.path ?? -1)
                  : undefined)
              }
              disabled={back?.disabled}
              loading={back?.loading}
              data-testid="multi-step-back"
            >
              {back?.text ?? "Back"}
            </Button>
          ) : null}
          {!primary?.hide ? (
            <Button
              appearance="primary"
              type={primary?.type}
              onPress={
                primary?.onPress ??
                (primary?.path && formNavigate
                  ? () => formNavigate(primary.path ?? "")
                  : undefined)
              }
              disabled={primary?.disabled}
              loading={primary?.loading}
              data-testid="multi-step-primary"
            >
              {primary?.text ?? "Next"}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
