import { useNavigate } from "react-router-dom";

import { Button, Heading, SpotIcon } from "@icg360/design-system";

import { Stack } from "components/common/stack";

import styles from "./ting-enrolled.module.scss";

export const TingEnrolled = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.layoutWrapper}>
      <SpotIcon name="CheckCircle" appearance="bold" />
      <Heading size="md">You&apos;re all set!</Heading>
      <Stack className={styles.content}>
        <p>
          Ting will follow up with your shipment information, so check your
          email (or your phone). If you have any questions, check out Ting’s
          FAQs.
        </p>
        <div className={styles.buttonWrapper}>
          <Button
            appearance="secondary"
            onPress={() => navigate("/my/settings/home-protection")}
          >
            View all property protection
          </Button>
          <Button onPress={() => navigate("/my/overview")}>Done</Button>
        </div>
      </Stack>
    </div>
  );
};
