import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { usePolicyHolderPhone } from "pages/settings/contact/contact-item/hooks";

import styles from "./ting-offer.module.scss";

export type TingContextState = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phone?: string;
  mobile?: ("isMobile" | "isTextOk")[];
  terms?: string;
  offerId?: string;
  from?: string;
} | null;

export const TingContext = createContext<{
  state: TingContextState;
  setState: Dispatch<SetStateAction<TingContextState>>;
}>({ state: null, setState: () => null });

export const TingChecker = () => {
  const { policy } = useContext(AuthAppContext);
  const { setFields, state } =
    useMultiStepFormState<TingContextState>(TingContext);
  const { state: locationState } = useLocation();
  const { policyHolderPhone, phoneLoading } = usePolicyHolderPhone(
    policy.policyId
  );

  const eligibilityData = useOutletContext<KeystoneOfferEligibility>();

  useEffect(() => {
    const terms = eligibilityData?.additionalDetails.find(
      (detail) => detail.assetTypeCode === "INTERNAL_TERMS_OF_USE"
    );
    setFields({
      terms: terms?.assetTextContent,
    });
  }, [eligibilityData?.additionalDetails, setFields]);

  useEffect(() => {
    if (eligibilityData?.offerId) {
      setFields({ offerId: eligibilityData.offerId ?? "" });
    }
  }, [eligibilityData?.offerId, setFields]);

  // This allows us to redirect back to where the user came from (home-protection or overview)
  useEffect(() => {
    if (locationState?.from) {
      setFields({ from: locationState.from });
    } else if (!state?.from) {
      setFields({ from: "/my/overview" });
    }
  }, [locationState?.from, setFields, state?.from]);

  useEffect(() => {
    if (policyHolderPhone && !state?.phone) {
      setFields({ phone: policyHolderPhone ?? "" });
    }
  }, [policyHolderPhone, setFields, state?.phone]);

  if (phoneLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <SpinnerLoader />
      </div>
    );
  }

  return <Outlet />;
};

export const TingFlow = () => {
  const [state, setState] = useState<TingContextState>(null);
  return (
    <TingContext.Provider value={{ state, setState }}>
      <TingChecker />
    </TingContext.Provider>
  );
};
