import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { authHeaders, handleUnauthorized, logError } from "utils";

export const useEligibility = () => {
  const navigate = useNavigate();
  const { policy } = useContext(AuthAppContext);
  const [eligibilityData, setEligibilityData] =
    useState<KeystoneOfferEligibility>();
  const [loading, setLoading] = useState(!policy?.insightPolicyId);

  const { pathname, state: locationState } = useLocation();

  useEffect(() => {
    if (!policy?.insightPolicyId) {
      return;
    }

    const handleRedirects = (data: KeystoneOfferEligibility) => {
      if (data.offerStatusCode === "INELIGIBLE") {
        // EXPIRED?
        navigate("/my/offer/ineligible", { replace: true });
      } else if (data.offerStatusCode === "ENROLLED") {
        navigate(`/my/offer/${data.offeringInternalName}/registered`, {
          replace: true,
        });
      } else {
        return;
      }
    };

    const handleNotOk = async (res, endpoint) => {
      if ([401, 403].includes(res.status)) {
        await handleUnauthorized({ endpoint });
      } else {
        logError(`Creating Offer: ${res.status} ${res.statusText}`);
        navigate("/my/overview");
      }
    };

    // Fetch the eligibility data if needed
    if (
      pathname.includes(eligibilityData?.offeringInternalName ?? "N/A") &&
      eligibilityData?.insightPolicyId === policy.insightPolicyId
    ) {
      handleRedirects(eligibilityData);
    } else {
      setLoading(true);
      (async () => {
        // TODO: Andre is going to make an /api/offer/<policyId>/offering/<offeringInternalName> endpoint so that we can do this in one call
        // We'll need to parse the offeringInternalName out of pathname
        const offersEndpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/offers/${policy.insightPolicyId}`;
        const res = await fetch(offersEndpoint, {
          credentials: "include",
          headers: authHeaders(),
        });
        if (res.ok) {
          const offer = (await res.json()).find((curOffer) =>
            pathname.includes(curOffer.offeringInternalName)
          );
          const offerDetailsEndpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/offer/${policy.insightPolicyId}/offering/${offer.offeringId}`;
          const offerRes = await fetch(offerDetailsEndpoint, {
            method: "POST",
            credentials: "include",
            headers: authHeaders(),
          });

          if (offerRes.ok) {
            const data = await offerRes.json();
            handleRedirects(data);
            setEligibilityData(data);
          } else {
            await handleNotOk(res, offerDetailsEndpoint);
            return;
          }
        } else {
          await handleNotOk(res, offersEndpoint);
          return;
        }
        setLoading(false);
      })();
    }
  }, [
    policy.insightPolicyId,
    navigate,
    pathname,
    locationState,
    eligibilityData,
  ]);

  return { eligibilityData, loading };
};
