import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { usePolicyHolderPhone } from "pages/settings/contact/contact-item/hooks";

import styles from "./shingle-reseal.module.scss";

export type ShingleResealContextState = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phone?: string;
  termsSageSure?: string;
  termsLiftLock?: string;
  offeringId?: string;
  offerId?: string;
  from?: string;
} | null;

export const ShingleContext = createContext<{
  state: ShingleResealContextState;
  setState: Dispatch<SetStateAction<ShingleResealContextState>>;
}>({ state: null, setState: () => null });

export const ShingleResealChecker = () => {
  const { policy } = useContext(AuthAppContext);
  const { setFields, state } =
    useMultiStepFormState<ShingleResealContextState>(ShingleContext);
  const { state: locationState } = useLocation();
  const { policyHolderPhone, phoneLoading } = usePolicyHolderPhone(
    policy.policyId
  );

  const eligibilityData = useOutletContext<KeystoneOfferEligibility>();

  useEffect(() => {
    const termsSageSure = eligibilityData?.additionalDetails.find(
      (detail) => detail.assetTypeCode === "INTERNAL_TERMS_OF_USE"
    );
    const termsLiftLock = eligibilityData?.additionalDetails.find(
      (detail) => detail.assetTypeCode === "PROVIDER_TERMS_OF_USE"
    );
    setFields({
      termsSageSure: termsSageSure?.assetTextContent,
      termsLiftLock: termsLiftLock?.assetTextContent,
    });
  }, [eligibilityData?.additionalDetails, setFields]);

  useEffect(() => {
    if (eligibilityData?.offeringId) {
      setFields({ offeringId: eligibilityData.offeringId });
    }
  }, [eligibilityData?.offerId, setFields]);

  useEffect(() => {
    if (eligibilityData?.offerId) {
      setFields({ offerId: eligibilityData.offerId });
    }
  }, [eligibilityData?.offerId, setFields]);

  // This allows us to redirect back to where the user came from (home-protection or overview)
  useEffect(() => {
    if (locationState?.from) {
      setFields({ from: locationState.from });
    } else if (!state?.from) {
      setFields({ from: "/my/overview" });
    }
  }, [locationState?.from, setFields, state?.from]);

  useEffect(() => {
    if (policyHolderPhone && !state?.phone) {
      setFields({ phone: policyHolderPhone ?? "" });
    }
  }, [policyHolderPhone, setFields, state?.phone]);

  if (phoneLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <SpinnerLoader />
      </div>
    );
  }

  return <Outlet />;
};

export const ShingleResealFlow = () => {
  const [state, setState] = useState<ShingleResealContextState>(null);
  return (
    <ShingleContext.Provider value={{ state, setState }}>
      <ShingleResealChecker />
    </ShingleContext.Provider>
  );
};
