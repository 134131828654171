import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Link as DSLink, Span } from "@icg360/design-system";

import { AuthAppDispatchContext } from "components/root/auth-app-provider";
import { useEnrollmentStatusDataQuery } from "gql/__generated__/hooks";
import { type Policy } from "gql/types";
import SettingsPolicy from "pages/settings/common/settings-policy";
import { useEligibleOffers } from "pages/settings/offers/hooks";
import { useFlags } from "utils";

import OfferCard from "./offer-card";
import styles from "./offers-item.module.scss";

type OffersItemProps = {
  policy: Policy;
};

const OffersItem = ({ policy }: OffersItemProps) => {
  const { shingleResealOffer } = useFlags();
  const navigate = useNavigate();
  const { setPolicy } = useContext(AuthAppDispatchContext);
  const { loading: enrollmentLoading, data: enrollmentStatusData } =
    useEnrollmentStatusDataQuery({
      skip: !policy.policyId,
      variables: { policyID: policy.policyId ?? "" },
    });
  const { isEnrolledInLeakBot } = enrollmentStatusData?.userDetails ?? {};

  const { eligibleOffers, loading } = useEligibleOffers(policy.insightPolicyId);

  const hasEligibleOffers = isEnrolledInLeakBot || eligibleOffers.length;

  return (
    <SettingsPolicy
      key={policy.policyId}
      isCommercial={!!policy.isCommercial}
      address={policy.propertyAddress}
      detailItems={[{ label: "Policy number:", value: policy.policyId }]}
      loading={loading || enrollmentLoading}
    >
      {!hasEligibleOffers ? (
        <Span color="quiet">
          This policy is currently not eligible for any of SageSure&apos;s home
          protection offerings.
        </Span>
      ) : (
        <div className={styles.offerCardsWrapper}>
          {eligibleOffers.map((offer) => {
            const enrolled = offer?.offerStatusCode === "ENROLLED";
            if (offer.offeringInternalName === "ting-offer") {
              return (
                <OfferCard
                  key="ting-offer"
                  title="Ting"
                  imgUrl="/assets/images/ting-promo.jpg"
                  imgAlt="a ting device and a phone"
                  productName="Enhance fire safety"
                  enrolledText={enrolled ? "Enrolled" : undefined}
                  buttonText="Sign up for free"
                  buttonAction={() => {
                    setPolicy(policy);
                    navigate(`/my/offer/ting-offer`, {
                      state: { from: "/my/settings/home-protection" },
                    });
                  }}
                  faqText="Have more questions?"
                  faqLink={
                    <DSLink
                      href="https://www.tingfire.com/help/faq/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View FAQ
                    </DSLink>
                  }
                />
              );
            } else if (
              shingleResealOffer &&
              offer.offeringInternalName === "shingle-opportunity"
            ) {
              // TODO: update faq link
              return (
                <OfferCard
                  key="shingle-opportunity"
                  title="Shingle resealing opportunity"
                  imgUrl="/assets/images/shingle-reseal-promo.jpg"
                  imgAlt="a person on a roof with a leaf blower performing preventative service"
                  productName="LiftLock's Wind Defense Research"
                  enrolledText={enrolled ? "Registered" : undefined}
                  buttonText="Get started"
                  buttonAction={() => {
                    setPolicy(policy);
                    navigate("/my/offer/shingle-opportunity", {
                      state: { from: "/my/settings/home-protection" },
                    });
                  }}
                  faqText="Have more questions?"
                  faqLink={
                    <DSLink
                      href="https://www.tingfire.com/help/faq/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View FAQ
                    </DSLink>
                  }
                />
              );
            } else {
              return null;
            }
          })}
          {isEnrolledInLeakBot ? (
            <OfferCard
              title="Monitor for leaks"
              imgUrl="/assets/images/leakbot-and-phone.png"
              imgAlt="a leakbot device and a phone"
              productName="LeakBot"
              buttonText={null}
              buttonAction={() => null}
              faqText="Got more questions about LeakBot?"
              faqLink={
                <DSLink
                  href="https://leakbot.io/support"
                  target="_blank"
                  rel="noreferrer"
                >
                  View LeakBot&apos;s FAQ page
                </DSLink>
              }
              enrolledText="enrolled"
            />
          ) : null}
        </div>
      )}
    </SettingsPolicy>
  );
};

export default OffersItem;
