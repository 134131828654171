import { useEffect, useState } from "react";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { CONFIG } from "config";
import {
  authHeaders,
  handleUnauthorized,
  logError,
  trackEvent,
  useFlags,
} from "utils";

const ELIGIBLE_STATUSES = ["SENT", "OFFERED", "ENROLLED", "ELIGIBLE"];

export const useEligibleOffers = (keystonePolicyId: string) => {
  const { shingleResealOffer, tingOffer } = useFlags();
  const [eligibleOffers, setEligibleOffers] = useState<
    KeystoneOfferEligibility[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!keystonePolicyId) {
      return;
    }
    const implementedOfferingNames: string[] = [];
    if (tingOffer) {
      implementedOfferingNames.push("ting-offer");
    }
    if (shingleResealOffer) {
      implementedOfferingNames.push("shingle-opportunity");
    }

    (async () => {
      setLoading(true);
      try {
        const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/offers/${keystonePolicyId}`;
        const res = await fetch(endpoint, {
          credentials: "include",
          headers: authHeaders(),
        });
        if (res.ok) {
          const offers = await res.json();
          setEligibleOffers(
            offers.filter(
              (offer) =>
                ELIGIBLE_STATUSES.includes(offer.offerStatusCode) &&
                implementedOfferingNames.includes(offer.offeringInternalName)
            )
          );
          trackEvent("Eligible Offers Loaded", {
            offers: offers.map((offer) => offer.offeringInternalName),
            keystonePolicyId,
          });
        } else {
          if ([401, 403].includes(res.status)) {
            await handleUnauthorized({ endpoint });
          }
        }
      } catch (err) {
        // swallow the error, we just won't show any offers
        logError(`settings offers: ${err.message}`);
      }
      setLoading(false);
    })();
  }, [keystonePolicyId, shingleResealOffer, tingOffer]);

  return { eligibleOffers, loading };
};
