import { useCallback, useContext, useState } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { type ShingleResealContextState } from "pages/offer/shingle-reseal/shingle-reseal-flow";
import { handleUnauthorized } from "utils";

export const useShingleResealEnrollment = (
  state: ShingleResealContextState
) => {
  const { policy } = useContext(AuthAppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { firstName, lastName, emailAddress, phone, offeringId, offerId } =
    state ?? {};

  const enrollShingleReseal = useCallback(async () => {
    setLoading(true);
    setError(false);
    const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/shinglereseal/enroll`;
    const res = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        offeringId,
        offerId,
        insightPolicyId: policy.insightPolicyId,
        additionalDetails: {
          firstName,
          lastName,
          emailAddress,
          phone,
        },
      }),
    });
    if (!res.ok) {
      if ([401, 403].includes(res.status)) {
        await handleUnauthorized({ endpoint });
      } else {
        setError(true);
      }
    }
    setLoading(false);

    return res.ok;
  }, [
    emailAddress,
    firstName,
    lastName,
    offerId,
    offeringId,
    phone,
    policy.insightPolicyId,
  ]);

  return { enrollShingleReseal, loading, error };
};
