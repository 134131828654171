import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, CheckboxGroup, Span } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { MultiStepFormLayout } from "components/multi-step-form-layout";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
// import { useTingEnrollment } from "pages/offer/ting/hooks";
import { TingContext, type TingContextState } from "pages/offer/ting/ting-flow";

import styles from "./ting-offer.module.scss";

export const Legal = () => {
  const navigate = useNavigate();
  const { state, formNavigate } =
    useMultiStepFormState<TingContextState>(TingContext);
  const [agreed, setAgreed] = useState(false);
  const [showError, setShowError] = useState(false);

  // const { enrollTing, loading, error } =
  //  useTingEnrollment(state);
  const loading = false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      !(
        state?.phone &&
        state?.firstName &&
        state?.lastName &&
        state?.emailAddress &&
        state?.mobile
      )
    ) {
      navigate("../policy-confirmation");
    }
  }, [
    navigate,
    state?.emailAddress,
    state?.firstName,
    state?.lastName,
    state?.phone,
    state?.mobile,
  ]);

  const onAgreeClick = (val) => {
    setAgreed(val);
    setShowError(false);
  };

  const onSubmit = async () => {
    if (!agreed) {
      setShowError(true);
      return;
    }

    // const success = await enrollTing();
    // if (success) {
    //   navigate("/my/offer/ting/registered");
    // }
    navigate("/my/offer/ting-offer/registered");
  };

  // if (error) {
  //   throw new Error("error signing up for Ting offer");
  // }

  return (
    <MultiStepFormLayout
      step={4}
      totalSteps={4}
      heading="Almost there! Please read and agree to the user agreement"
      buttonProps={{
        primary: { text: "Submit", onPress: onSubmit, loading: loading },
        cancel: { path: state?.from ?? "/" },
        formNavigate,
      }}
    >
      <Stack>
        <div className={styles.terms}>
          {state?.terms?.split("\n").map((paragraph, i) => (
            <p key={i}>
              {i === 0 ? (
                <Button
                  icon="Print"
                  appearance="secondary"
                  size="xs"
                  className={styles.printButtonDS}
                  onPress={print}
                  data-testid="waiver-print"
                />
              ) : null}
              {paragraph}
            </p>
          ))}
        </div>
        <Span className={styles.printHideDS}>
          <CheckboxGroup
            isError={showError}
            errorMessage="Please agree to the waiver before submitting."
            aria-label="I have read and agreed to Ting's user agreement."
          >
            <CheckboxGroup.Checkbox
              onChange={onAgreeClick}
              isInvalid={showError}
            >
              I have read and agreed to Ting&apos;s user agreement.
            </CheckboxGroup.Checkbox>
          </CheckboxGroup>
        </Span>
      </Stack>
    </MultiStepFormLayout>
  );
};
