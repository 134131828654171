import { useContext } from "react";

import { Heading, SpotIcon } from "@icg360/design-system";

import { MultiStepFormLayout } from "components/multi-step-form-layout";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { formatTwoLineAddress } from "utils";

import { TingContext, type TingContextState } from "./ting-flow";
import styles from "./ting-offer.module.scss";

export const PolicyConfirmation = () => {
  const { policy } = useContext(AuthAppContext);
  const { formNavigate } = useMultiStepFormState<TingContextState>(TingContext);

  if (!policy.propertyAddress) {
    return null;
  }

  const { lineOne, lineTwo } = formatTwoLineAddress(
    policy.propertyAddress,
    true
  );

  return (
    <MultiStepFormLayout
      step={1}
      totalSteps={4}
      heading="Is this your address?"
      subheading="You can only use Ting for the property below while your policy is in effect."
      buttonProps={{
        primary: { path: "../contact-information" },
        formNavigate,
      }}
    >
      <div className={styles.iconItem}>
        <SpotIcon name="Home" appearance="bold" />
        <div>
          <Heading size="sm">{lineOne}</Heading>
          <Heading size="sm">{lineTwo}</Heading>
          <div className={styles.subtext}>Policy number: {policy.policyId}</div>
        </div>
      </div>
    </MultiStepFormLayout>
  );
};
